export const ControllerBundleCustomization = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerBundleCustomization.js' /* webpackChunkName: "components/controller-bundle-customization" */).then(c => wrapFunctional(c.default || c))
export const ControllerBundles = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerBundles.js' /* webpackChunkName: "components/controller-bundles" */).then(c => wrapFunctional(c.default || c))
export const ControllerCalendar = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCalendar.js' /* webpackChunkName: "components/controller-calendar" */).then(c => wrapFunctional(c.default || c))
export const ControllerCart = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCart.js' /* webpackChunkName: "components/controller-cart" */).then(c => wrapFunctional(c.default || c))
export const ControllerCategory = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCategory.js' /* webpackChunkName: "components/controller-category" */).then(c => wrapFunctional(c.default || c))
export const ControllerCommentList = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCommentList.js' /* webpackChunkName: "components/controller-comment-list" */).then(c => wrapFunctional(c.default || c))
export const ControllerConfirmEmail = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerConfirmEmail.js' /* webpackChunkName: "components/controller-confirm-email" */).then(c => wrapFunctional(c.default || c))
export const ControllerContactForm = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerContactForm.js' /* webpackChunkName: "components/controller-contact-form" */).then(c => wrapFunctional(c.default || c))
export const ControllerCookie = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCookie.js' /* webpackChunkName: "components/controller-cookie" */).then(c => wrapFunctional(c.default || c))
export const ControllerCrossSell = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCrossSell.js' /* webpackChunkName: "components/controller-cross-sell" */).then(c => wrapFunctional(c.default || c))
export const ControllerCustomForm = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCustomForm.js' /* webpackChunkName: "components/controller-custom-form" */).then(c => wrapFunctional(c.default || c))
export const ControllerError = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerError.js' /* webpackChunkName: "components/controller-error" */).then(c => wrapFunctional(c.default || c))
export const ControllerEvent = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerEvent.js' /* webpackChunkName: "components/controller-event" */).then(c => wrapFunctional(c.default || c))
export const ControllerFeaturedProducts = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerFeaturedProducts.js' /* webpackChunkName: "components/controller-featured-products" */).then(c => wrapFunctional(c.default || c))
export const ControllerFooter = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerFooter.js' /* webpackChunkName: "components/controller-footer" */).then(c => wrapFunctional(c.default || c))
export const ControllerHeader = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerHeader.js' /* webpackChunkName: "components/controller-header" */).then(c => wrapFunctional(c.default || c))
export const ControllerLayeredNavigation = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerLayeredNavigation.js' /* webpackChunkName: "components/controller-layered-navigation" */).then(c => wrapFunctional(c.default || c))
export const ControllerNavigationBar = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerNavigationBar.js' /* webpackChunkName: "components/controller-navigation-bar" */).then(c => wrapFunctional(c.default || c))
export const ControllerNavigationItem = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerNavigationItem.js' /* webpackChunkName: "components/controller-navigation-item" */).then(c => wrapFunctional(c.default || c))
export const ControllerNewsletter = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerNewsletter.js' /* webpackChunkName: "components/controller-newsletter" */).then(c => wrapFunctional(c.default || c))
export const ControllerPost = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPost.js' /* webpackChunkName: "components/controller-post" */).then(c => wrapFunctional(c.default || c))
export const ControllerPostCategory = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostCategory.js' /* webpackChunkName: "components/controller-post-category" */).then(c => wrapFunctional(c.default || c))
export const ControllerPostCategoryList = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostCategoryList.js' /* webpackChunkName: "components/controller-post-category-list" */).then(c => wrapFunctional(c.default || c))
export const ControllerPostList = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostList.js' /* webpackChunkName: "components/controller-post-list" */).then(c => wrapFunctional(c.default || c))
export const ControllerPostReview = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostReview.js' /* webpackChunkName: "components/controller-post-review" */).then(c => wrapFunctional(c.default || c))
export const ControllerProduct = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProduct.js' /* webpackChunkName: "components/controller-product" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductCategories = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductCategories.js' /* webpackChunkName: "components/controller-product-categories" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductConfiguration = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductConfiguration.js' /* webpackChunkName: "components/controller-product-configuration" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductCustomOptions = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductCustomOptions.js' /* webpackChunkName: "components/controller-product-custom-options" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductList = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductList.js' /* webpackChunkName: "components/controller-product-list" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductListContainer = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductListContainer.js' /* webpackChunkName: "components/controller-product-list-container" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductsListFiltered = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductsListFiltered.js' /* webpackChunkName: "components/controller-products-list-filtered" */).then(c => wrapFunctional(c.default || c))
export const ControllerPublicUpload = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPublicUpload.js' /* webpackChunkName: "components/controller-public-upload" */).then(c => wrapFunctional(c.default || c))
export const ControllerRecentlyViewedProducts = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerRecentlyViewedProducts.js' /* webpackChunkName: "components/controller-recently-viewed-products" */).then(c => wrapFunctional(c.default || c))
export const ControllerRelatedProducts = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerRelatedProducts.js' /* webpackChunkName: "components/controller-related-products" */).then(c => wrapFunctional(c.default || c))
export const ControllerSearch = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerSearch.js' /* webpackChunkName: "components/controller-search" */).then(c => wrapFunctional(c.default || c))
export const ControllerUpSell = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerUpSell.js' /* webpackChunkName: "components/controller-up-sell" */).then(c => wrapFunctional(c.default || c))
export const ControllerUserComment = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerUserComment.js' /* webpackChunkName: "components/controller-user-comment" */).then(c => wrapFunctional(c.default || c))
export const ControllerAddress = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/checkout/ControllerAddress.js' /* webpackChunkName: "components/controller-address" */).then(c => wrapFunctional(c.default || c))
export const ControllerPayment = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/checkout/ControllerPayment.js' /* webpackChunkName: "components/controller-payment" */).then(c => wrapFunctional(c.default || c))
export const ControllerShipping = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/checkout/ControllerShipping.js' /* webpackChunkName: "components/controller-shipping" */).then(c => wrapFunctional(c.default || c))
export const ControllerChangePassword = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerChangePassword.js' /* webpackChunkName: "components/controller-change-password" */).then(c => wrapFunctional(c.default || c))
export const ControllerForgotPassword = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerForgotPassword.js' /* webpackChunkName: "components/controller-forgot-password" */).then(c => wrapFunctional(c.default || c))
export const ControllerLogin = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerLogin.js' /* webpackChunkName: "components/controller-login" */).then(c => wrapFunctional(c.default || c))
export const ControllerOrderHistory = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerOrderHistory.js' /* webpackChunkName: "components/controller-order-history" */).then(c => wrapFunctional(c.default || c))
export const ControllerRegister = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerRegister.js' /* webpackChunkName: "components/controller-register" */).then(c => wrapFunctional(c.default || c))
export const ControllerResetPassword = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerResetPassword.js' /* webpackChunkName: "components/controller-reset-password" */).then(c => wrapFunctional(c.default || c))
export const ControllerSocialLogin = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerSocialLogin.js' /* webpackChunkName: "components/controller-social-login" */).then(c => wrapFunctional(c.default || c))
export const ControllerSocialLoginButtons = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerSocialLoginButtons.js' /* webpackChunkName: "components/controller-social-login-buttons" */).then(c => wrapFunctional(c.default || c))
export const ControllerUser = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerUser.js' /* webpackChunkName: "components/controller-user" */).then(c => wrapFunctional(c.default || c))
export const ControllerWishlist = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerWishlist.js' /* webpackChunkName: "components/controller-wishlist" */).then(c => wrapFunctional(c.default || c))
export const EditorIcon = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorIcon.js' /* webpackChunkName: "components/editor-icon" */).then(c => wrapFunctional(c.default || c))
export const EditorImage = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorImage.js' /* webpackChunkName: "components/editor-image" */).then(c => wrapFunctional(c.default || c))
export const EditorImageMarkers = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorImageMarkers.js' /* webpackChunkName: "components/editor-image-markers" */).then(c => wrapFunctional(c.default || c))
export const EditorInstagramPostList = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorInstagramPostList.js' /* webpackChunkName: "components/editor-instagram-post-list" */).then(c => wrapFunctional(c.default || c))
export const EditorLink = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorLink.js' /* webpackChunkName: "components/editor-link" */).then(c => wrapFunctional(c.default || c))
export const EditorList = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorList.js' /* webpackChunkName: "components/editor-list" */).then(c => wrapFunctional(c.default || c))
export const EditorListItem = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorListItem.js' /* webpackChunkName: "components/editor-list-item" */).then(c => wrapFunctional(c.default || c))
export const EditorMap = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorMap.js' /* webpackChunkName: "components/editor-map" */).then(c => wrapFunctional(c.default || c))
export const EditorPostList = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorPostList.js' /* webpackChunkName: "components/editor-post-list" */).then(c => wrapFunctional(c.default || c))
export const EditorProductCategoryList = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorProductCategoryList.js' /* webpackChunkName: "components/editor-product-category-list" */).then(c => wrapFunctional(c.default || c))
export const EditorProductList = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorProductList.js' /* webpackChunkName: "components/editor-product-list" */).then(c => wrapFunctional(c.default || c))
export const EditorTipTap = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorTipTap.js' /* webpackChunkName: "components/editor-tip-tap" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesCookieElement = () => import('../../components/cmsOverrides/CookieElement.vue' /* webpackChunkName: "components/cms-overrides-cookie-element" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesErrorView = () => import('../../components/cmsOverrides/ErrorView.vue' /* webpackChunkName: "components/cms-overrides-error-view" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesFooterElement = () => import('../../components/cmsOverrides/FooterElement.vue' /* webpackChunkName: "components/cms-overrides-footer-element" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesHeaderElement = () => import('../../components/cmsOverrides/HeaderElement.vue' /* webpackChunkName: "components/cms-overrides-header-element" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesPostList = () => import('../../components/cmsOverrides/PostList.vue' /* webpackChunkName: "components/cms-overrides-post-list" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesPostView = () => import('../../components/cmsOverrides/PostView.vue' /* webpackChunkName: "components/cms-overrides-post-view" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesSearchView = () => import('../../components/cmsOverrides/SearchView.vue' /* webpackChunkName: "components/cms-overrides-search-view" */).then(c => wrapFunctional(c.default || c))
export const UiButtonComponent = () => import('../../components/ui/ButtonComponent.vue' /* webpackChunkName: "components/ui-button-component" */).then(c => wrapFunctional(c.default || c))
export const UiInputComponent = () => import('../../components/ui/InputComponent.vue' /* webpackChunkName: "components/ui-input-component" */).then(c => wrapFunctional(c.default || c))
export const UiSocialShare = () => import('../../components/ui/SocialShare.vue' /* webpackChunkName: "components/ui-social-share" */).then(c => wrapFunctional(c.default || c))
export const DevToolsInView = () => import('../../components/devTools/inView.vue' /* webpackChunkName: "components/dev-tools-in-view" */).then(c => wrapFunctional(c.default || c))
export const SectionsTagPostFilter = () => import('../../components/sections/TagPostFilter.vue' /* webpackChunkName: "components/sections-tag-post-filter" */).then(c => wrapFunctional(c.default || c))
export const SectionsH1Section = () => import('../../components/sections/h1Section.vue' /* webpackChunkName: "components/sections-h1-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsAboutUsAwardsAndAcknowledgments = () => import('../../components/sections/AboutUs/AwardsAndAcknowledgments.vue' /* webpackChunkName: "components/sections-about-us-awards-and-acknowledgments" */).then(c => wrapFunctional(c.default || c))
export const SectionsAboutUsDirectlyTalk = () => import('../../components/sections/AboutUs/DirectlyTalk.vue' /* webpackChunkName: "components/sections-about-us-directly-talk" */).then(c => wrapFunctional(c.default || c))
export const SectionsAboutUsDirectorsBoard = () => import('../../components/sections/AboutUs/DirectorsBoard.vue' /* webpackChunkName: "components/sections-about-us-directors-board" */).then(c => wrapFunctional(c.default || c))
export const SectionsAboutUsFuelingTheFuture = () => import('../../components/sections/AboutUs/FuelingTheFuture.vue' /* webpackChunkName: "components/sections-about-us-fueling-the-future" */).then(c => wrapFunctional(c.default || c))
export const SectionsAboutUsHeroSection = () => import('../../components/sections/AboutUs/HeroSection.vue' /* webpackChunkName: "components/sections-about-us-hero-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsAboutUsInnovationAtGauss = () => import('../../components/sections/AboutUs/InnovationAtGauss.vue' /* webpackChunkName: "components/sections-about-us-innovation-at-gauss" */).then(c => wrapFunctional(c.default || c))
export const SectionsAboutUsOurValues = () => import('../../components/sections/AboutUs/OurValues.vue' /* webpackChunkName: "components/sections-about-us-our-values" */).then(c => wrapFunctional(c.default || c))
export const SectionsAboutUsQualityAtGauss = () => import('../../components/sections/AboutUs/QualityAtGauss.vue' /* webpackChunkName: "components/sections-about-us-quality-at-gauss" */).then(c => wrapFunctional(c.default || c))
export const SectionsAboutUsTimelineSection = () => import('../../components/sections/AboutUs/TimelineSection.vue' /* webpackChunkName: "components/sections-about-us-timeline-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsAboutUsWeAreGauss = () => import('../../components/sections/AboutUs/WeAreGauss.vue' /* webpackChunkName: "components/sections-about-us-we-are-gauss" */).then(c => wrapFunctional(c.default || c))
export const SectionsCareersApplicationForm = () => import('../../components/sections/Careers/ApplicationForm.vue' /* webpackChunkName: "components/sections-careers-application-form" */).then(c => wrapFunctional(c.default || c))
export const SectionsCareersJobRequirements = () => import('../../components/sections/Careers/JobRequirements.vue' /* webpackChunkName: "components/sections-careers-job-requirements" */).then(c => wrapFunctional(c.default || c))
export const SectionsCareersOpenPositions = () => import('../../components/sections/Careers/OpenPositions.vue' /* webpackChunkName: "components/sections-careers-open-positions" */).then(c => wrapFunctional(c.default || c))
export const SectionsCareersOurBenefits = () => import('../../components/sections/Careers/OurBenefits.vue' /* webpackChunkName: "components/sections-careers-our-benefits" */).then(c => wrapFunctional(c.default || c))
export const SectionsCareersPositionHero = () => import('../../components/sections/Careers/PositionHero.vue' /* webpackChunkName: "components/sections-careers-position-hero" */).then(c => wrapFunctional(c.default || c))
export const SectionsCareersTitleText = () => import('../../components/sections/Careers/TitleText.vue' /* webpackChunkName: "components/sections-careers-title-text" */).then(c => wrapFunctional(c.default || c))
export const SectionsClutchAboutUs = () => import('../../components/sections/Clutch/ClutchAboutUs.vue' /* webpackChunkName: "components/sections-clutch-about-us" */).then(c => wrapFunctional(c.default || c))
export const SectionsClutchClients = () => import('../../components/sections/Clutch/ClutchClients.vue' /* webpackChunkName: "components/sections-clutch-clients" */).then(c => wrapFunctional(c.default || c))
export const SectionsClutchCounter = () => import('../../components/sections/Clutch/ClutchCounter.vue' /* webpackChunkName: "components/sections-clutch-counter" */).then(c => wrapFunctional(c.default || c))
export const SectionsClutchFAQ = () => import('../../components/sections/Clutch/ClutchFAQ.vue' /* webpackChunkName: "components/sections-clutch-f-a-q" */).then(c => wrapFunctional(c.default || c))
export const SectionsClutchHero = () => import('../../components/sections/Clutch/ClutchHero.vue' /* webpackChunkName: "components/sections-clutch-hero" */).then(c => wrapFunctional(c.default || c))
export const SectionsClutchProjects = () => import('../../components/sections/Clutch/ClutchProjects.vue' /* webpackChunkName: "components/sections-clutch-projects" */).then(c => wrapFunctional(c.default || c))
export const SectionsClutchTestimonials = () => import('../../components/sections/Clutch/ClutchTestimonials.vue' /* webpackChunkName: "components/sections-clutch-testimonials" */).then(c => wrapFunctional(c.default || c))
export const SectionsHomepageAuthenticReliable = () => import('../../components/sections/Homepage/AuthenticReliable.vue' /* webpackChunkName: "components/sections-homepage-authentic-reliable" */).then(c => wrapFunctional(c.default || c))
export const SectionsHomepageBookAMeeting = () => import('../../components/sections/Homepage/BookAMeeting.vue' /* webpackChunkName: "components/sections-homepage-book-a-meeting" */).then(c => wrapFunctional(c.default || c))
export const SectionsHomepageBusinessSoftware = () => import('../../components/sections/Homepage/BusinessSoftware.vue' /* webpackChunkName: "components/sections-homepage-business-software" */).then(c => wrapFunctional(c.default || c))
export const SectionsHomepageContactSection = () => import('../../components/sections/Homepage/ContactSection.vue' /* webpackChunkName: "components/sections-homepage-contact-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsHomepageHero = () => import('../../components/sections/Homepage/HomepageHero.vue' /* webpackChunkName: "components/sections-homepage-hero" */).then(c => wrapFunctional(c.default || c))
export const SectionsHomepageLatestBlogs = () => import('../../components/sections/Homepage/LatestBlogs.vue' /* webpackChunkName: "components/sections-homepage-latest-blogs" */).then(c => wrapFunctional(c.default || c))
export const SectionsHomepageOurServicesHome = () => import('../../components/sections/Homepage/OurServicesHome.vue' /* webpackChunkName: "components/sections-homepage-our-services-home" */).then(c => wrapFunctional(c.default || c))
export const SectionsHomepageOurWork = () => import('../../components/sections/Homepage/OurWork.vue' /* webpackChunkName: "components/sections-homepage-our-work" */).then(c => wrapFunctional(c.default || c))
export const SectionsHomepageQuestionsAnswers = () => import('../../components/sections/Homepage/QuestionsAnswers.vue' /* webpackChunkName: "components/sections-homepage-questions-answers" */).then(c => wrapFunctional(c.default || c))
export const SectionsHomepageStatisticsSection = () => import('../../components/sections/Homepage/StatisticsSection.vue' /* webpackChunkName: "components/sections-homepage-statistics-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsHomepageTestimonialsSection = () => import('../../components/sections/Homepage/TestimonialsSection.vue' /* webpackChunkName: "components/sections-homepage-testimonials-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsContactHero = () => import('../../components/sections/Contact/ContactHero.vue' /* webpackChunkName: "components/sections-contact-hero" */).then(c => wrapFunctional(c.default || c))
export const SectionsContactLocationAndContact = () => import('../../components/sections/Contact/LocationAndContact.vue' /* webpackChunkName: "components/sections-contact-location-and-contact" */).then(c => wrapFunctional(c.default || c))
export const SectionsContactMultipleContactForms = () => import('../../components/sections/Contact/MultipleContactForms.vue' /* webpackChunkName: "components/sections-contact-multiple-contact-forms" */).then(c => wrapFunctional(c.default || c))
export const SectionsContactThankYou = () => import('../../components/sections/Contact/ThankYou.vue' /* webpackChunkName: "components/sections-contact-thank-you" */).then(c => wrapFunctional(c.default || c))
export const SectionsOurWorkImageAndCategories = () => import('../../components/sections/OurWork/ImageAndCategories.vue' /* webpackChunkName: "components/sections-our-work-image-and-categories" */).then(c => wrapFunctional(c.default || c))
export const SectionsOurWorkNextInLine = () => import('../../components/sections/OurWork/NextInLine.vue' /* webpackChunkName: "components/sections-our-work-next-in-line" */).then(c => wrapFunctional(c.default || c))
export const SectionsOurWorkHero = () => import('../../components/sections/OurWork/OurWorkHero.vue' /* webpackChunkName: "components/sections-our-work-hero" */).then(c => wrapFunctional(c.default || c))
export const SectionsOurWorkPrincipleSection = () => import('../../components/sections/OurWork/PrincipleSection.vue' /* webpackChunkName: "components/sections-our-work-principle-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsOurWorkProjectInfo = () => import('../../components/sections/OurWork/ProjectInfo.vue' /* webpackChunkName: "components/sections-our-work-project-info" */).then(c => wrapFunctional(c.default || c))
export const SectionsOurWorkSpecialProjectInfo = () => import('../../components/sections/OurWork/SpecialProjectInfo.vue' /* webpackChunkName: "components/sections-our-work-special-project-info" */).then(c => wrapFunctional(c.default || c))
export const SectionsOurWorkAndProducts = () => import('../../components/sections/OurWork/WorkAndProducts.vue' /* webpackChunkName: "components/sections-our-work-and-products" */).then(c => wrapFunctional(c.default || c))
export const SectionsPrivacyPolicy = () => import('../../components/sections/PrivacyPolicy/PrivacyPolicy.vue' /* webpackChunkName: "components/sections-privacy-policy" */).then(c => wrapFunctional(c.default || c))
export const SectionsServicesOurApproach = () => import('../../components/sections/Services/OurApproach.vue' /* webpackChunkName: "components/sections-services-our-approach" */).then(c => wrapFunctional(c.default || c))
export const SectionsServicesOurProcess = () => import('../../components/sections/Services/OurProcess.vue' /* webpackChunkName: "components/sections-services-our-process" */).then(c => wrapFunctional(c.default || c))
export const SectionsServicesOurServices = () => import('../../components/sections/Services/OurServices.vue' /* webpackChunkName: "components/sections-services-our-services" */).then(c => wrapFunctional(c.default || c))
export const SectionsServicesSingleServiceContactUs = () => import('../../components/sections/Services/SingleServiceContactUs.vue' /* webpackChunkName: "components/sections-services-single-service-contact-us" */).then(c => wrapFunctional(c.default || c))
export const SectionsServicesSingleServiceHero = () => import('../../components/sections/Services/SingleServiceHero.vue' /* webpackChunkName: "components/sections-services-single-service-hero" */).then(c => wrapFunctional(c.default || c))
export const SectionsServicesSingleServiceMeasureSuccess = () => import('../../components/sections/Services/SingleServiceMeasureSuccess.vue' /* webpackChunkName: "components/sections-services-single-service-measure-success" */).then(c => wrapFunctional(c.default || c))
export const SectionsServicesSingleServiceOurPlatform = () => import('../../components/sections/Services/SingleServiceOurPlatform.vue' /* webpackChunkName: "components/sections-services-single-service-our-platform" */).then(c => wrapFunctional(c.default || c))
export const SectionsServicesSingleServiceProjects = () => import('../../components/sections/Services/SingleServiceProjects.vue' /* webpackChunkName: "components/sections-services-single-service-projects" */).then(c => wrapFunctional(c.default || c))
export const SectionsServicesSingleServiceQuote = () => import('../../components/sections/Services/SingleServiceQuote.vue' /* webpackChunkName: "components/sections-services-single-service-quote" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
